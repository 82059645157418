<template>
  <ion-content class="prime-compliance-page">
    <div class="prime-compliance-page-container">
      <img class="mypcp-logo" src="../assets/logo.svg" />
      <p v-html="pageCopy.privacyPolicy.content"></p>
      <footerCompliance></footerCompliance>
    </div>
  </ion-content>
</template>

<script>
import FooterCompliance from "@/components/Global/FooterCompliance";

export default {
  name: "Privacy",
  components: {
    FooterCompliance
  },
  data() {
    return {
      pageCopy: this.$languagePack
    };
  }
};
</script>

<style scoped>
.prime-compliance-page {
  background: var(--ion-background-color);
}
.prime-compliance-page-container {
  max-width: 740px;
  margin: 30px auto;
  padding: 0 20px;
}
.prime-compliance-page-container .mypcp-logo {
  width: 340px;
  display: block;
  margin: 30px auto;
}
.prime-compliance-page-container h1 {
  margin-bottom: 30px;
}
@media screen and (max-width: 540px) {
  .prime-compliance-page-container .mypcp-logo {
    width: 220px;
  }
}
</style>
